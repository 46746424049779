import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../../image/logo.PNG";
import Footer from "./Footer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    paddingHorizontal: 35,
    paddingVertical: 30,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  logo: {
    width: 150,
    height: 50,
  },
  title: {
    fontSize: 24,
    fontFamily: "Helvetica", // Using Helvetica which is a default font
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#003366",
    color: "white",
    padding: 20,
    fontSize: 12,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  footerText: {
    marginHorizontal: 10,
  },
  footerIcon: {
    marginHorizontal: 5,
    fontSize: 10,
  },
  iconContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  verticalLine: {
    height: "100%",
    width: 1,
    backgroundColor: "white",
    marginHorizontal: 10,
  },
  icon: {
    fontFamily: "FontAwesome",
    fontSize: 12,
    color: "white",
    marginHorizontal: 5,
  },
  tableHeader: {
    display: "table",
    width: "auto",
    backgroundColor: "#003366",
    fontSize: 11,
    fontWeight: "extrabold",
    color: "white",
    padding: 8,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    marginTop: 10,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderLeft: 1,
    borderRight: 1,
    borderColor: "#bfbfbf",
    marginBottom: 15,
  },
  DetailsTable: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    marginBottom: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    fontSize: 10,
  },
  tableCol: {
    fontSize: 10,
    // borderWidth: 1,
    borderColor: "#bfbfbf",
    padding: 5,
  },
  row1: {
    flexDirection: "row",
    color: "#424949",
    // border: '2px solid red'
    borderBottom: 1,
    borderColor: "#bfbfbf",
  },
  row2: {
    flexDirection: "row",
    color: "#424949",
    // border: '2px solid red'
  },
  col1: {
    borderRight: 1,
    borderColor: "#bfbfbf",
    width: "25%",
    color: "#424949",
    // border: '2px solid red'
    padding: 5,
  },
  col2: {
    width: "29%",
    color: "black",
    // border: '2px solid red'
    padding: 5,
  },
  col3: {
    borderRight: 1,
    borderLeft: 1,
    borderColor: "#bfbfbf",
    width: "23%",
    color: "#424949",
    // border: '2px solid red'
    padding: 5,
  },
  col4: {
    borderRight: 1,
    borderLeft: 1,
    borderColor: "#bfbfbf",
    width: "23%",
    color: "#424949",
    // border: '2px solid red'
    padding: 5,
  },
  col5: {
    borderRight: 1,
    borderColor: "#bfbfbf",
    width: "23.6%",
    color: "#424949",
    // border: '2px solid red'
    padding: 5,
  },
  col6: {
    borderColor: "#bfbfbf",
    width: "76.4%",
    color: "black",
    // border: '2px solid red'
    padding: 5,
  },
  content1: {
    padding: 5,
    color: "#424949",
    fontSize: 10,
    // border: '2px solid red'
    borderRight: 1,
    borderColor: "#bfbfbf",
  },
  row1Result: {
    padding: 5,
    color: "black",
    fontSize: 10,
    // border: '2px solid red'
  },
  content2: {
    padding: 5,
    color: "#424949",
    fontSize: 10,
    // border: '2px solid red'
  },
  row2Result: {
    padding: 5,
    color: "black",
    fontSize: 10,
    // border: '2px solid red'
  },
  content3: {
    padding: 5,
    color: "#424949",
    width: "15%",
    fontSize: 10,
    // border: '2px solid red'
  },
  row3Result: {
    padding: 5,
    color: "black",
    width: "20%",
    fontSize: 10,
    // border: '2px solid red'
  },
  tableRow2: {
    flexDirection: "row",
    borderTop: 1,
    borderBottom: 1,
    borderColor: "#bfbfbf",
  },
  content5: {
    padding: 5,
    color: "#424949",
    width: "15%",
    fontSize: 10,
    // border: '2px solid red'
    borderRight: 1,
    borderColor: "#bfbfbf",
  },
  row5Result: {
    padding: 5,
    color: "#424949",
    width: "85%",
    fontSize: 10,
    // border: '2px solid red'
  },
  row: {
    flexDirection: "row",
    borderBottom: 1,
    borderColor: "#bfbfbf",
  },
  column1: {
    width: "85%",
  },
  result: {
    width: "15%,",
    fontSize: 10,
    alignItems: "center",
    padding: 5,
  },
  tableRow1: {
    flexDirection: "row",
    borderBottom: 1,
    borderColor: "#bfbfbf",
  },
  content2_1: {
    padding: 5,
    color: "#424949",
    width: "70%",
    fontSize: 10,
    // border: '2px solid red'
  },
  rowResult2_1: {
    padding: 5,
    color: "black",
    width: "10%",
    fontSize: 10,
    // border: '2px solid red'
  },
  row4: {
    flexDirection: "column",
    backgroundColor: "#fffb00",
    opacity: "0.2",
    // border: 1,
    borderColor: "#bfbfbf",
    marginBottom: 15,
    borderRadius: 5,
    padding: 4,
  },
  content4: {
    paddingTop: 5,
    paddingLeft: 5,
    color: "#8f8408",
    width: "50%",
    fontSize: 12,
    // border: '2px solid red',
    opacity: "12",
  },
  row4Result: {
    padding: 5,
    paddingBottom: 8,
    color: "#424949",
    width: "100%",
    fontSize: 10,
    // border: '2px solid red',
    opacity: "1",
  },
  signatureSection: {
    flexDirection: "column",
    backgroundColor: "#d7dbdd",
    // opacity: "0.2",
    // border: 1,
    borderColor: "#bfbfbf",
    marginBottom: 15,
    marginTop: 5,
    borderRadius: 5,
    padding: 5,
  },
  signature: {
    width: 260,
    height: 100,
    objectFit: "contain",
    alignSelf: "flex-end",
    marginTop: 5,
    marginBottom: 6,
  },
  signatureContainer: {
    fontSize: 10,
    color: "#626567",
    alignSelf: "flex-end",
    alignItems: "center",
    // border: '2px solid red',
    marginBottom: 18,
    marginLeft: 6,
    marginRight: 40,
  },
  descriptionSection: {
    flexDirection: "column",
    backgroundColor: "#d7dbdd",
    // opacity: "0.2",
    // border: 1,
    borderColor: "#bfbfbf",
    marginBottom: 15,
    borderRadius: 5,
    padding: 5,
  },
  description: {
    padding: 5,
    color: "#424949",
    fontSize: 10,
    borderBottom: 1,
    borderColor: "#bfbfbf",
    // border: '2px solid red'
  },subTopic: {
    backgroundColor: "#fffb00",
    opacity: "0.2",
    padding: 5,
  },
  subTopicText: {
    fontSize: 12,
    opacity: "1",
    color: "#8f8408",
  },
  noSignature: {
    marginBottom: 15,
    marginTop: 15,
    marginRight: 5,
    color: "black",
  },
});

const SmokeReport = ({ data, result, signature }) => {
  return (
    <Document title="Smoke Safety Check Report">
      <Page size={[656, 790]} style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <Image style={styles.logo} src={logo} alt="Company Logo" />
          <Text style={styles.title}>Smoke Safety Check</Text>
        </View>

        <>
          {/* Details Section */}
          <View style={styles.DetailsTable}>
            <View style={styles.row1}>
              <View style={styles.col1}>
                {" "}
                <Text style={styles.text1}>Name:</Text>
              </View>
              <View style={styles.col2}>
                {" "}
                <Text style={styles.text2}>{data.firstName + " " + data.lastName}</Text>
              </View>
            </View>

            <View style={styles.row1}>
              <View style={styles.col1}>
                {" "}
                <Text style={styles.text1}>Address:</Text>
              </View>
              <View style={styles.col2}>
                {" "}
                <Text style={styles.text2}>{data.address}</Text>
              </View>
            </View>

            <View style={styles.row1}>
              <View style={styles.col1}>
                {" "}
                <Text style={styles.text1}>Inquiry Id:</Text>
              </View>
              <View style={styles.col2}>
                {" "}
                <Text style={styles.text2}>{data.customerInquiryId}</Text>
              </View>
            </View>

            <View style={styles.row1}>
              <View style={styles.col1}>
                {" "}
                <Text style={styles.text1}>Inspection Date:</Text>
              </View>
              <View style={styles.col2}>
                {" "}
                <Text style={styles.text2}>{data.serviceDate}</Text>
              </View>
            </View>

            <View style={styles.row2}>
              <View style={styles.col1}>
                {" "}
                <Text style={styles.text1}>Inspected by:</Text>
              </View>
              <View style={styles.col2}>
                {" "}
                <Text style={styles.text2}>{data.technicianId.firstName +
                    " " +
                    data.technicianId.lastName}</Text>
              </View>
            </View>
          </View>
        </>

        {/* Description Section */}
        {/* <View style={styles.descriptionSection}>
          <Text style={{ padding: 5, fontSize: 10, color: "#2e4053" }}>
            A tick in a box below indicates that on the Inspection Date and at
            the Inspection Address, the smoke alarms and smoke alarm
            installations were inspected and met the relevant requirements of
            the noted legislation.
          </Text>

          <Text style={{ padding: 5, fontSize: 10, color: "#2e4053" }}>
            A cross in a box below indicates that on the Inspection Date and at
            the Inspection Address, the smoke alarms and smoke alarm
            installations were inspected and did not meet the relevant
            requirements of the noted legislation.
          </Text>
        </View>

        <View>
          <Text style={styles.tableHeader}>Smoke Alarms</Text>
          <View style={styles.table}>
            <View style={styles.description}>
              <Text>
                At this inspection, the following alarms were present or
                installed.
              </Text>
            </View>

            <View style={styles.subTopic}>
              <Text style={styles.subTopicText}>
                VOLTAGE     TYPE    EXPIRES     LOCATION               ROOM     LEVEL    INSTALLATION REASON 
              </Text>
            </View>
          </View>
        </View> */}

        <View>
        <Text style={styles.tableHeader}>Smoke Alarm Check</Text>
        <View style={styles.table}>
          <View style={styles.row}>
            <View style={{ flexDirection: "row" }}>
              <View style={styles.column1}>
                <Text style={styles.content1}>
                  All smoke alarm are correctly installed and in working
                  condition; and have been tested according to the
                  manufacturer's instructions.
                </Text>
              </View>
              <View style={[styles.result, { justifyContent: "center" }]}>
                <Text>{result?.titles[0].subTitles[0].descriptions[0].status}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      {/*Section E*/}
      <View>
        <Text style={styles.tableHeader}>
          Observations and Recommendations for Any Actions to be Taken
        </Text>
        <View style={styles.table}>
          {result?.titles[1]?.subTitles[0]?.descriptions?.map((item, index) => (
            <View key={index} style={styles.row}>
              <Text style={[styles.content1, { borderRight: 0 }]}>
                {item.description}
              </Text>
            </View>
          ))}
        </View>
      </View>

        {/* Declaration Table */}
        <>
          <View style={styles.signatureSection}>
            <Text style={{ padding: 5, fontSize: 12, color: "#2e4053" }}>
              Signature of the technician
            </Text>
            <View style={styles.signatureContainer}>
            {signature ? (
                      <Image
                        style={styles.signature}
                        src={signature}
                        alt="Signature"
                      />
                    ) : (
                      <Text style={styles.noSignature}>
                        No signature available
                      </Text>
                    )}
              <Text>Signed by the technician</Text>
            </View>
          </View>
        </>

        {/* Footer */}
        <Footer/>
      </Page>
    </Document>
  );
};

export default SmokeReport;

/*import { Button } from '@material-tailwind/react'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { TextField } from '@mui/material'
import React from 'react'

function Payment() {
    return (
        <div className='flex flex-col w-screen px-16 mt-10'>
            <div className='flex flex-col w-full h-[350px] bg-gray-200 px-10 pt-12'>
              
                <div className='flex flex-col w-full'>
                    <div className='text-4xl font-bold'>Payment</div>
                    <div className='pt-1 text-sm'>Choose Payment Method</div>
                </div>
                <div className='flex flex-row w-full mt-4'>
                    <div className='w-[250px] h-[150px] border-2 rounded-lg border-black flex flex-col justify-center items-center mr-12'>
                        <img src='Pay4.svg' alt='' width={90} height={30} className='pt-3' />
                        <div className='pt-2 text-sm font-semibold'>Pay with Visa Card</div>
                    </div>

                    <div className='w-[250px] h-[150px] border-2 rounded-lg border-black flex flex-col justify-center items-center mr-12'>
                        <img src='Pay3.svg' alt='' width={80} height={20} />
                        <div className='text-sm font-semibold '>Pay with Visa Card</div>
                    </div>

                    <div className='w-[250px] h-[150px] border-2 rounded-lg border-black flex flex-col justify-center items-center mr-12'>
                        <img src='Pay2.svg' alt='' width={65} height={20} />
                        <div className='text-sm font-semibold '>Pay with Visa Card</div>
                    </div>

                    <div className='w-[250px] h-[150px] border-2 rounded-lg border-black flex flex-col justify-center items-center mr-12'>
                        <img src='Pay1.svg' alt='' width={120} height={30} className='pt-3' />
                        <div className='pt-2 text-sm font-semibold'>Pay with Visa Card</div>
                    </div>
                </div>


            </div>

            <div className='flex flex-row gap-5'>
          
            <div className='flex flex-col w-1/2 h-[540px] pt-10 bg-gray-200 mt-10 px-20'>
                <div className='pb-10 text-xl font-bold'>Credit Card Information</div>
                <div className='flex flex-col w-full'>
                    <div className='flex flex-row w-full pt-2'>
                        <TextField className='w-[500px] rounded-3xl' />
                    </div>
                    <div className='pt-2'>Card Holder Name</div>
                </div>

                <div className='flex flex-col w-full pt-4'>
                    <div className='flex flex-row w-full pt-2'>
                        <TextField className='w-[500px] rounded-3xl' />
                    </div>
                    <div className='pt-2'>Card Holder Name</div>
                </div>

                <div className='flex flex-row justify-between pt-4 pr-9'>
                    <div className='flex flex-col pr-10'>
                        <TextField className='bg-gray-200 w-[220px]' />
                        <div className='pt-2'>Expiry Date</div>
                    </div>
                    <div className='flex flex-col'>
                        <TextField className='bg-gray-200 w-[220px] ' />
                        <div className='pt-2'>Expiry Date</div>
                    </div>
                </div>

                <div className='flex flex-row pt-10'>
                    <CheckBox/>
                    <div className='pl-2'>Save my details for future payment</div>
                </div>
                
                
            </div>

            <div className='flex flex-col w-full h-[540px] bg-gray-200 mt-10 pt-10 px-10'>
                <div className='pb-10 text-xl font-bold'>Work Order Summary</div>
                <div className='flex flex-row justify-between w-full'>
                    <div>Technician</div>
                    <div>$200</div>
                </div>
                    <div className='flex flex-row justify-between w-full pt-10'>
                        <div>Technician</div>
                        <div>$200</div>
                    </div>

                    <div className='flex flex-row w-full h-[2px] bg-black mt-4'></div>
                    <div className='flex flex-row justify-between w-full pt-2'>
                        <div>Total</div>
                        <div>$300</div>
                    </div>
            </div>
            </div>
            <Button className=' w-[200px] mt-10   bg-gradient-to-r from-[#FCCF00] to-[#002252] text-sm font-bold'>Confirm Payments</Button>
        </div>
    )
}

export default Payment
*/



import React from "react";
import Stripe from "react-stripe-checkout";
import { request, axiosInstance1 } from "../../config/axiosConfig";

function Payment() {

  async function handleToken(stripetoken) {
    console.log(stripetoken);
    try {
      await request(
        axiosInstance1,
        "POST",
        "api/payment/charge",
        { token: stripetoken.id, amount: 500 }, // Data to be sent in the request body
      );
      alert("Payment Success");
    } catch (error) {
      alert("Payment Error: " + error.message);
    }
  }

  return (
    <div className='flex flex-col w-screen px-16 mt-10'>
      <div className='flex flex-col w-full h-[350px] bg-gray-200 px-10 pt-12'>
        <div className='flex flex-col w-full h-[540px] bg-gray-200 mt-10 pt-10 px-10'>
          <div className='pb-10 text-xl font-bold'>Work Order Summary</div>
          <div className='flex flex-row justify-between w-full'>
            <div>Technician</div>
            <div>$300</div>
          </div>
          <div className='flex flex-row justify-between w-full pt-10'>
            <div>Technician</div>
            <div>$200</div>
          </div>
          <div className='flex flex-row w-full h-[2px] bg-black mt-4'></div>
          <div className='flex flex-row justify-between w-full pt-2'>
            <div>Total</div>
            <div>$ 500</div>
          </div>
        </div>
      </div>
      <br/><br/>
      <div>
        <Stripe
          stripeKey="pk_live_51PFstdGnEP0APj6JG2SXQfxrvUfXq1EmmGnmYnVg50pocP0ONaSYh9CKe9paogS2qwzDD6uDHHUCynuuuTJF3SsL00nDX5vYX0"
          token={handleToken} // Correct prop name is 'token'
        />
      </div>
    </div>
  );
}

export default Payment;




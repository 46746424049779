import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { smokeChecklistItems } from "./List";

const SmokeChecklist = ({ onSubmit, submitting }) => {
  const checklist = smokeChecklistItems;

  const [smokeAlarmCheckedYes, setSmokeAlarmCheckedYes] = useState(false);
  const [smokeAlarmCheckedNo, setSmokeAlarmCheckedNo] = useState(false);
  const [observationsList, setObservationsList] = useState([""]);
  const [error, setError] = useState(false);

  const inquiryId = localStorage.getItem("InquiryId");
  const userId = localStorage.getItem("userid");

  const handleSmokeAlarmChangeYes = () => {
    setSmokeAlarmCheckedYes(true);
    setSmokeAlarmCheckedNo(false);
  };

  const handleSmokeAlarmChangeNo = () => {
    setSmokeAlarmCheckedYes(false);
    setSmokeAlarmCheckedNo(true);
  };

  const handleObservationsChange = (index, event) => {
    const newObservations = [...observationsList];
    newObservations[index] = event.target.value;
    setObservationsList(newObservations);

    if (error && event.target.value.trim() !== "") {
      setError(false);
    }
  };

  const handleAddObservation = () => {
    if (observationsList[observationsList.length - 1].trim() === "") {
      setError(true);
    } else {
      setObservationsList([...observationsList, ""]);
      setError(false);
    }
  };

  const handleRemoveObservation = () => {
    if (observationsList.length > 1) {
      const newObservations = observationsList.slice(0, -1);
      setObservationsList(newObservations);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSubmit = [];

    const addItem = (title, subTitle, item, status) => {
      dataToSubmit.push({
        user: { id: userId },
        inquiry: { customerInquiryId: inquiryId },
        type: "Smoke",
        title,
        subTitle,
        description: item,
        status,
      });
    };

    const description4 =
      "All smoke alarm are correctly installed and in working condition; and have been tested according to the manufacturer's instructions.";
    const status4 = smokeAlarmCheckedYes
      ? "Yes"
      : smokeAlarmCheckedNo
      ? "No"
      : "N/A";
    addItem(checklist.subTitle1, "", description4, status4);

    observationsList.forEach((observation) => {
      const description = observation;
      addItem(checklist.subTitle2, "", description, "");
    });

    onSubmit(dataToSubmit, handleClearForm());
  };

  const handleClearForm = () => {
    setSmokeAlarmCheckedYes("");
    setSmokeAlarmCheckedNo("");
    setObservationsList([]);
  };

  return (
    <Box
      sx={{
        width: "60%",
        height: "50%",
        margin: "auto",
        alignItems: "center",
        boxShadow: 2,
        borderRadius: 5,
        padding: 6,
        backgroundColor: "white",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          marginTop={3}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            variant="h7"
            sx={{
              marginBottom: 2,
              fontWeight: "bold",
              backgroundColor: "#FCCF00",
              padding: 1,
            }}
          >
            {checklist.subTitle1}
          </Typography>
          <Typography variant="h8">
            All smoke alarm are correctly installed and in working condition;
            and have been tested according to the manufacturer's instructions.
          </Typography>
          <Grid
            sm={2.2}
            item
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "rgba(128, 128, 128, 0.2)",
              paddingLeft: 1,
              marginTop: 1,
              borderRadius: 2,
            }}
          >
            <FormControlLabel
              label="Yes"
              control={
                <Checkbox
                  checked={smokeAlarmCheckedYes}
                  onChange={handleSmokeAlarmChangeYes}
                />
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={smokeAlarmCheckedNo}
                  onChange={handleSmokeAlarmChangeNo}
                />
              }
              label="No"
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          marginTop={3}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            variant="h7"
            sx={{
              marginBottom: 2,
              fontWeight: "bold",
              backgroundColor: "#FCCF00",
              padding: 1,
            }}
          >
            {checklist.subTitle2}
          </Typography>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
            }}
          >
            <Grid item xs={12} sm={9}>
              {observationsList.map((value, index) => (
                <div key={index}>
                  <TextField
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Enter your observations here"
                    value={value}
                    onChange={(event) => handleObservationsChange(index, event)}
                    sx={{ mb: 2 }}
                    error={error && index === observationsList.length - 1}
                    helperText={
                      error && index === observationsList.length - 1
                        ? "Field is empty"
                        : ""
                    }
                  />
                </div>
              ))}
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                onClick={handleAddObservation}
                sx={{ color: "#002252", mb: 2 }}
              >
                + Add Observation
              </Button>
              {observationsList.length > 1 && (
                <Button onClick={handleRemoveObservation} sx={{ color: "red" }}>
                  - Remove Observation
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Button
          fullWidth
          type="submit"
            onClick={handleSubmit}
          variant="contained"
          sx={{
            marginTop: 3,
            backgroundColor: "#002252",
            color: "#FFFFFF",
            border: "2px solid transparent", // Default border
            "&:hover": {
              backgroundColor: "#FFFFFF", // Background color on hover
              color: "#002252", // Text color on hover (blue)
              border: "2px solid #002252", // Border color on hover (blue)
            },
            "&:disabled": {
              color: "#abb2b9",
              border: "none",
              backgroundColor: "#e5e7e9",
            },
          }}
          disabled={submitting}
        >
          {submitting ? "Please wait..." : "Submit"}
        </Button>

        <Button
            onClick={handleClearForm}
          fullWidth
          variant="outlined"
          sx={{
            mt: 1,
            mb: 2,
            borderRadius: 2,
          }}
        >
          Clear Form
        </Button>
      </Grid>
    </Box>
  );
};

export default SmokeChecklist;

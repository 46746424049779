import React, { useEffect, useState } from "react";
import { axiosInstance2, request } from "../../config/axiosConfig";
import CompletedInquiryCard from "../../components/dashboard/InquiryCard";
import AdminViewDetailsPopup from "../../components/dashboard/AdminViewDetails";
import { CircularProgress } from "@mui/material";

const AdminCompletedInquiries = () => {
  const [Inquirys, setInquiries] = useState([]);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [currentStatus, setCurrentStatus] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchInquiries();
  }, []);

  const fetchInquiries = async () => {
    try {
      setLoading(true);
      const response = await request(
        axiosInstance2,
        "GET",
        "/getAllCompletedInquiries",
        null
      );
      setInquiries(response.data);
    } catch (error) {
      console.error("Error fetching Inquiries:", error);
    } finally {
      setLoading(false);
    }
  };

  const openDetailsPopup = (technicianId, overallStatus, inquiryData) => {
    setShowDetailsPopup(true);
    setCurrentStatus(overallStatus);
    setSelectedTechnician(technicianId);
    setSelectedStatus(overallStatus);
    setSelectedCustomer(inquiryData);
  };

  const closeDetailsPopup = () => {
    setSelectedTechnician(null);
    setCurrentStatus(null);
    setSelectedCustomer(null);
    setSelectedStatus(null);
    setShowDetailsPopup(false);
  };

  return (
    <div className="flex flex-column justify-start w-full px-4 mt-4 mb-4 text-xl font-bold text-gray-800 md:px-16 sm:text-2xl lg:text-2xl">
      Completed Inquiries
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <CircularProgress />
        </div>
      ) : Inquirys.length === 0 ? (
        <div className="flex items-left h-full mt-2">
          <p className="text-gray-500 text-lg font-semibold">
            You do not have completed inquiries.
          </p>
        </div>
      ) : (
        <div className="overflow-y-auto max-h-[500px] mb-4 mt-8">
          {Inquirys.map((InquiryData, index) => (
            <CompletedInquiryCard
              key={index}
              inquiryData={InquiryData}
              openDetailsPopup={openDetailsPopup}
            />
          ))}
        </div>
      )}
      {showDetailsPopup && (
        < AdminViewDetailsPopup
          closeDetailsPopup={closeDetailsPopup}
          selectedTechnician={selectedTechnician}
          overallStatus={currentStatus}
          selectedCustomer={selectedCustomer}
        />
      )}
    </div>
  );
};

export default AdminCompletedInquiries;

export const electricalChecklistItems = {
  title: "Electrical Safety Checklist",
  subTitle1: "A. Extent of The Installation and Limitations of The Inspection and Testing",
  subTitle2: "B. Safety Check - Verified by Visual Inspection",
  subTitle3: "C. Safety Check - Verified by Visual Inspection",
  subTitle4: "D. Observations and Recommendations for Any Actions to be Taken",
  subTitle3a: "Polarity and correct connection testing",
  subTitle3b: "Earth continuity testing",
  subItems1: [
    "Main Switchboard",
    "Main earthing system",
    "Kitchen",
    "Bathroom (main)",
    "Other bathrooms/ensuites",
    "Bedroom (main)",
    "Other bedrooms",
    "Living room",
    "Other living areas",
    "Laundry",
    "Garage",
    "Solar/battery system",
    "Electric water heater",
    "Dishwasher",
    "Electric room/space heaters",
    "Swimming pool equipment",
  ],
  subItems2: [
    "Consumers mains",
    "Switchboards",
    "Exposed earth electrode",
    "Metallic water pipe bond",
    "RCDs(Safety switches)",
    "Circuit protection(circuit breakers/ fuses)",
    "Socket-outlets",
    "Light fittings",
    "Electric water heater",
    "Air conditioners",
    "Space heaters",
    "Cooking equipment",
    "Dishwasher",
    "Exhaust fans",
    "Celling fans",
    "Washing machine/dryer",
    "Installation wiring",
    "Solar and other renewable systems",
    "Swimming pool equipment",
    "Vehicle chargers",
  ],
  subItems3: {
    subItems3a: [
      "Consumers mains",
      "Circuit protection(circuit breakers/ fuses)",
      "RCDs(Safety switches)",
      "Socket-outlets",
      "Light fittings",
      "Electric water heater",
      "Air conditioners",
      "Cooking equipment",
      "Dishwasher",
      "Solar and other renewable systems",
      "Swimming pool equipment",
      "Vehicle chargers",
    ],
    subItems3b: [
      "Mains earth conductor",
      "Metallic water pipe bond",
      "Socket-outlets",
      "Light fittings",
      "Exhaust fans",
      "Celling fans",
      "Electric water heater",
      "Air conditioners",
      "Cooking equipment",
      "Dishwasher",
      "Solar and other renewable systems",
      "Swimming pool equipment",
      "Vehicle chargers",
    ],
  },
};

export const smokeChecklistItems = {
  title: "Smoke Safety Checklist",
  subTitle1: "Smoke Alarm Check",
  subTitle2: "Observations and Recommendations for Any Actions to be Taken",
};
